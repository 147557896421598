<template>
  <b-row>
    <b-col cols="12">
      <StripChart
        :chartData="stripData"
        :breakdown="digitalBreakDown"
        :title="title"
        :subtitle="subtitle"
        :widthBy="widthBy"
        :total="total"
        @getDataBreakdown="getDataBreakdown"
        :showLegend="showLegend"
      />
    </b-col>
    <b-col
      cols="12"
      v-if="digitalBreakDown"
      class="horizontal-breakdownable-card"
      :style="{ '--left': selectedData.value / 2 + valueAdded + '%' }"
    >
      <x-range-chart :series="data" />
    </b-col>
  </b-row>
</template>
<script>
import StripChart from "@/components/Chart/StripChart.vue";
import XRangeChart from "@/components/Chart/XRangeChart.vue";
export default {
  name: "CampaignType",
  props: {
    widthBy: {
      type: Boolean,
      default: false,
    },
    showLegend:{
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default: () => [],
    },
    stripData: {
      type: Array,
      default: () => [],
    },
    total: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      digitalBreakDown: false,
      selectedData: "",
      valueAdded: 0,
    };
  },
  components: {
    StripChart,
    XRangeChart,
  },
  methods: {
    findIndexPosition(data) {
      this.valueAdded = 0;
      let ind = this.stripData.findIndex((obj) => obj.label === data.label);
      if (ind > 0) {
        for (var i = 0; i < ind; i++) {
          var value = this.stripData[i].value;
          this.valueAdded += value;
        }
      } else {
        this.valueAdded = 0;
      }
    },
    getDigitalBreakdown(newVal) {
      this.digitalBreakDown = newVal;
    },
    getDataBreakdown(data) {
      // this.selectedData = data.data;
      // this.findIndexPosition(data.data);
      // this.getDigitalBreakdown(data.breakdown);
      this.$emit("getDataBreakdown", data.data);
    },
  },
};
</script>

<style scoped>
.horizontal-breakdownable-card {
  border-top: 2px solid #d4dae4;
  position: relative;
}

.horizontal-breakdownable-card::before {
  content: "";
  width: 40px;
  height: 20px;
  position: absolute;
  background: #fff;
  top: -17px;
  transform: translateX(-50%);
  left: var(--left);
  z-index: 9999;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.horizontal-breakdownable-card::after {
  content: "";
  width: 40px;
  height: 20px;
  position: absolute;
  background: #d4dae4;
  top: -20px;
  transform: translateX(-50%);
  left: var(--left);
  z-index: 999;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
</style>
