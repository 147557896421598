<template>
  <div class="row">
    <div class="col-5 pr-0 pt-4">
      <p class="d-none">{{ this.chartData.categories }}</p>
      <bar-chart ref="barChart1" :options="leftChartOptions"></bar-chart>
    </div>
    <div class="col-2 px-1 px-lg-4">
      <div class="text-center arrow-wrapper">
        <img
          v-if="chartData.startIndex != 0"
          @click="scrollUp"
          class="small-card-icon"
          src="@/assets/arrow-up-s-line.svg"
        />
      </div>
      <div
        class="content"
        v-for="(data, index) in chartData.middleData.slice(
          chartData.startIndex,
          chartData.endIndex
        )"
        :key="index"
        :class="{ 'active-content': activeData === data }"
        @click="handleUpdateContent(data)"
      >
        {{ data }}
      </div>
      <div class="text-center arrow-wrapper">
        <img
          v-if="chartData.middleData.length !== chartData.endIndex"
          @click="scrollDown"
          class="small-card-icon"
          src="@/assets/arrow-down-s-line.svg"
        />
      </div>
    </div>
    <div class="col-5 pl-0 pt-4">
      <bar-chart ref="barChart2" :options="rightChartOptions"></bar-chart>
    </div>
  </div>
</template>
<script>
import { Chart } from "highcharts-vue";
import { abbreviateNumber } from "js-abbreviation-number";
export default {
  name: "TwoBarChart",
  components: {
    barChart: Chart,
  },
  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
    activeData: {
      type: String,
      default: "",
    },
  },
  watch: {
    activeData(val) {
      this.leftChartOptions.series.data = [];
      this.rightChartOptions.series.data = [];
      if (val === "") {
        return;
      }
      const leftSeriesObj = this.chartData.series[0].data.find(
        (element) => element.name == val
      );
      this.leftChartOptions.series.data = leftSeriesObj.data;
      const rightSeriesObj = this.chartData.series[1].data.find(
        (element) => element.name == val
      );
      this.rightChartOptions.series.data = rightSeriesObj.data;
    },
  },
  data() {
    return {
      leftChartOptions: {
        colors: this.chartData.colors.slice(0, 1),
        chart: {
          type: "bar",
          height: this.chartData.height,
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: this.chartData.categories,
          title: {
            text: null,
          },
          visible: true,
          opposite: true,
          labels: {
            enabled: true,
          },
          crosshair: {
            enabled: true,
            events: {},
          },
        },
        yAxis: {
          visible: true,
          reversed: true,
          min: 0,
          title: {
            text: this.chartData.series[0].name,
            align: "middle",
            margin: 20,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
          gridLineDashStyle: "shortdash",
          lineWidth: 1,
        },
        tooltip: {
          valueSuffix: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
            events: {
              click: function(event) {
                alert("chla", event);
              },
            },
          },
          series: {
            borderRadiusBottomRight: 50,
            borderRadiusBottomLeft: 50,
            dataLabels: {
              enabled: true,
              formatter: function() {
                return abbreviateNumber(this.y, 1, {
                  symbols: ["", "k", "M", "B", "T", "P", "E"],
                });
              },
            },
            pointPadding: 0.2,
            point: {
              events: {
                click: function() {
                  alert("Category: " + this.category);
                },
              },
            },
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        series: {
          name: this.chartData.series[0].name,
          data: [],
        },
      },
      rightChartOptions: {
        colors: this.chartData.colors.slice(1, 2),
        chart: {
          type: "bar",
          height: this.chartData.height,
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: this.chartData.categories,
          title: {
            text: null,
          },
          visible: true,
          labels: {
            enabled: true,
          },
          crosshair: {
            enabled: true,
            events: {},
          },
          //   tickColor: "transparent",
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: this.chartData.series[1].name,
            align: "middle",
            margin: 20,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
          labels: {
            enabled: true,
          },
          gridLineDashStyle: "shortdash",
          lineWidth: 1,
          isDirty: true,
        },
        tooltip: {
          valueSuffix: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            borderRadiusTopRight: 50,
            borderRadiusTopLeft: 50,
            pointPadding: 0.2,
            dataLabels: {
              enabled: true,
              formatter: function() {
                return abbreviateNumber(this.y, 1, {
                  symbols: ["", "k", "M", "B", "T", "P", "E"],
                });
              },
            },
            point: {
              events: {
                click: function() {
                  alert("Category: " + this.category);
                },
              },
            },
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        series: {
          name: this.chartData.series[1].name,
          data: [],
        },
      },
    };
  },
  mounted() {
    if (this.leftChartOptions.xAxis.crosshair.events === undefined) {
      this.leftChartOptions.xAxis.crosshair = {};
      this.leftChartOptions.xAxis.crosshair.enabled = false;
    }
    const theChart1 = this.$refs.barChart1;
    this.leftChartOptions.tooltip = {
      shared: true,
      formatter: function(tooltip) {
        theChart1.columnIndex = theChart1.options.xAxis.categories.indexOf(
          this.x
        );
        return tooltip.defaultFormatter.call(this, tooltip);
      },
    };
    if (this.rightChartOptions.xAxis.crosshair.events === undefined) {
      this.rightChartOptions.xAxis.crosshair = {};
      this.rightChartOptions.xAxis.crosshair.enabled = false;
    }
    const theChart2 = this.$refs.barChart2;
    this.rightChartOptions.tooltip = {
      shared: true,
      formatter: function(tooltip) {
        theChart2.columnIndex = theChart2.options.xAxis.categories.indexOf(
          this.x
        );
        return tooltip.defaultFormatter.call(this, tooltip);
      },
    };

    var self = this;
    this.leftChartOptions.xAxis.crosshair.events = {
      click: function() {
        const category =
          theChart1.options.xAxis.categories[theChart1.columnIndex];
        self.$emit("columnClick", category);
        alert(category);
      },
    };
    this.rightChartOptions.xAxis.crosshair.events = {
      click: function() {
        const category =
          theChart2.options.xAxis.categories[theChart2.columnIndex];
        self.$emit("columnClick", category);
        alert(category);
      },
    };
    this.updateData();
  },
  beforeUpdate() {
    this.updateData();
  },
  methods: {
    updateData() {
      if (this.activeData === "") {
        return;
      }
      var leftSeriesObj = this.chartData.series[0].data.find(
        (element) => element.name == this.activeData
      );

      var rightSeriesObj = this.chartData.series[1].data.find(
        (element) => element.name == this.activeData
      );
      if (leftSeriesObj === undefined || rightSeriesObj === undefined) {
        this.leftChartOptions.series.data = [];
        this.rightChartOptions.series.data = [];
        return;
      }
      this.leftChartOptions.series.data = leftSeriesObj.data;
      this.rightChartOptions.series.data = rightSeriesObj.data;
      this.leftChartOptions.xAxis.categories = this.chartData.categories;
      this.rightChartOptions.xAxis.categories = this.chartData.categories;
      this.rightChartOptions.series.name = this.chartData.series[1].name;
      this.leftChartOptions.series.name = this.chartData.series[0].name;
      this.leftChartOptions.yAxis.title.text = this.chartData.series[0].name;
      this.rightChartOptions.yAxis.title.text = this.chartData.series[1].name;
    },
    handleUpdateContent(data) {
      this.$emit("updateContent", data);
    },
    // updateSeries() {
    //   this.leftChartOptions.xAxis.categories = this.chartData.categories.slice(
    //         this.chartData.startIndex,
    //         this.chartData.endIndex
    //       )
    //   this.leftChartOptions.series = {
    //     name: this.chartData.series.slice(0, 1)[0].name,
    //     data: this.chartData.series
    //       .slice(0, 1)[0]
    //       .data.slice(this.chartData.startIndex, this.chartData.endIndex),
    //   };

    //   this.rightChartOptions.xAxis.categories = this.chartData.categories.slice(
    //         this.chartData.startIndex,
    //         this.chartData.endIndex
    //       )
    //   this.rightChartOptions.series = {
    //     name: this.chartData.series.slice(1, 2)[0].name,
    //     data: this.chartData.series
    //       .slice(1, 2)[0]
    //       .data.slice(this.chartData.startIndex, this.chartData.endIndex),
    //   };
    // },
    scrollUp() {
      // const index = this.chartData.middleData.indexOf(this.activeData);
      // if (index <= this.chartData.startIndex) {
      this.$emit("updateIndex", {
        startIndex: this.chartData.startIndex - 1,
        endIndex: this.chartData.endIndex - 1,
      });
      // }
      // this.$emit("updateContent", this.chartData.middleData[index - 1]);
    },
    scrollDown() {
      // const index = this.chartData.middleData.indexOf(this.activeData);
      // if (index >= this.chartData.endIndex - this.chartData.startIndex - 1) {
      this.$emit("updateIndex", {
        startIndex: this.chartData.startIndex + 1,
        endIndex: this.chartData.endIndex + 1,
      });
      // }
      // this.$emit("updateContent", this.chartData.middleData[index + 1]);
    },
  },
};
</script>

<style scoped>
.content {
  font-size: 16px;
  color: #222a37;
  font-family: ProximaNovaRegular;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
}
.active-content {
  background-color: #eff5ff;
  font-family: ProximaNovaBold;
  font-size: 20px;
}
::v-deep .highcharts-root {
  padding: 0;
}

.arrow-wrapper {
  height: 24px;
}

@media screen and (max-width: 768px) {
  .content {
    font-size: 14px;
    padding: 15px 0;
  }
  .active-content {
    font-size: 16px;
  }
}
</style>
